import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function formatTitle(title, site) {
  if (title === site.siteMetadata.title)
    return title;
  return `${title} | ${site.siteMetadata.title}`
}

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const Meta = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const metaDescription = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={formatTitle(title, site)}
      meta={[{ name: `description`, content: metaDescription }]}
    />
  )
}

export default Meta
